var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "preview-wrap"
  }, [_c('div', {
    staticClass: "preview",
    attrs: {
      "id": _vm.id
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }