<template>
  <div class="preview-wrap">
    <div class="preview" :id="id" />
  </div>
</template>

<script>
import {setTheme} from "@/utils/editor.js";

let componentController = [];
window.setRender = [];

export default {
  data() {
    return {
      id: "",
      theme: null,
      page: 0,
      device: null,
      components: null,
      componentIds: [],
      componentContainer: {}
    };
  },
  mounted() {
    window.addEventListener("message", e => {
      this.action(e.data);
    });
    window.addEventListener("resize", this.scale);
  },
  destroyed() {
    window.removeEventListener("message", e => {
      this.action(e.data);
    });
    window.removeEventListener("resize", this.scale);
  },
  methods: {
    action(data) {
      // init >> {action: 'intit', theme: '', device: '', id: ''}
      // device >> {action: 'device', device: ''}
      // page >> {action: 'page', page: ''}  >> test필요
      // layout >> {action: 'layout', componentLayout: ''}  >> test필요
      // option >> {action: 'option', id: '', linkedOptions: ''}
      // margin >> {action: 'margin', id: '', etc: ''}
      // display >> {action: 'display', id: '', visible: '', displayOnPc: '', displayOnMobile: ''}
      // typefaces >> {action: 'typefaces', typefaces: ''}
      // add >> {action: 'add', componentLayout: '', componentContainer: ''}
      // remove >> {action: 'remove', ids: '', componentLayout: '', componentContainer: ''}
      // move >> {action: 'move', componentLayout: ''}
      // focus >> {action: 'focus', id: ''}
      // refresh (에디터 저장 후 호출) >> {action: 'refresh', componentLayout: '', componentContainer: ''}
      // reset (테마 되돌리기 후 호출) >> {action: 'reset', theme: ''}
      // component (component 미리보기) >> {action: 'component', device: '', componentContainer: ''}
      console.log(data);
      // theme init
      if (data.action === "init") {
        this.device = data.device;
        this.id = data.id;
        this.init(data.theme);
        this.scale();
      }
      // change device
      if (data.action === "device") {
        this.device = data.device;
        for (let id of this.componentIds) {
          const component = document.querySelector(`#component${id}`);
          component.shadowRoot.innerHTML = null;
          this.reDraw(id);
          this.setDisplay(id);
        }
        this.scale();
      }
      // change page
      if (data.action === "page") {
        this.page = data.page;
        for (let id of this.componentIds) {
          this.remove(id);
        }
        this.setComponent();
        for (let id of this.componentIds) {
          this.draw(id);
        }
      }
      // change layout
      if (data.action === "layout") {
        for (let id of this.componentIds) {
          this.remove(id);
        }
        this.components.componentLayout[this.page] = data.componentLayout;
        this.setComponent();
        for (let id of this.componentIds) {
          this.draw(id);
        }
      }
      // change option
      if (data.action === "option") {
        this.componentContainer.get(data.id).linkedOptions = data.linkedOptions;
        this.reDraw(data.id);
      }
      // change margin
      if (data.action === "margin") {
        this.componentContainer.get(data.id).etc = data.etc;
        this.setMargin(data.id);
      }
      // change display
      if (data.action === "display") {
        this.componentContainer.get(data.id).visible = data.visible;
        this.componentContainer.get(data.id).displayOnPc = data.displayOnPc;
        this.componentContainer.get(data.id).displayOnMobile =
          data.displayOnMobile;
        this.setDisplay(data.id);
      }
      // change typefaces
      if (data.action === "typefaces") {
        this.theme.typefaces.css = data.typefaces;
        this.setTypefaces();
      }
      // component remove
      if (data.action === "remove") {
        this.components.componentLayout[this.page] = data.componentLayout;
        this.components.componentContainer[this.page] = data.componentContainer;
        this.setComponent();
        this.remove(data.ids);
      }
      // component add
      if (data.action === "add") {
        this.components.componentLayout[this.page] = data.componentLayout;
        this.components.componentContainer[this.page] = data.componentContainer;
        this.setComponent();
        this.add();
      }
      // component move
      if (data.action === "move") {
        this.components.componentLayout[this.page] = data.componentLayout;
        this.setComponent();
        this.move();
      }
      // component focus
      if (data.action === "focus") {
        this.focus(data.id);
      }
      // theme refresh (에디터 저장 후 호출)
      if (data.action === "refresh") {
        this.components.componentLayout[this.page] = data.componentLayout;
        this.components.componentContainer[this.page] = data.componentContainer;
        this.setComponent();
        this.refresh();
      }
      // theme reset (테마 되돌리기 후 호출)
      if (data.action === "reset") {
        for (let id of this.componentIds) {
          this.remove(id);
        }
        const style = document.querySelector(".preview-wrap style");
        style.remove();
        this.init(data.theme);
      }
      // component preview (컴포넌트 미리보기)
      if (data.action === "component") {
        this.device = data.device;
        this.componentIds.push(1);
        this.componentContainer = data.componentContainer;
        this.draw(1);
        this.scale();
      }
    },
    init(data) {
      // component set
      this.theme = data;
      this.components = setTheme(data);
      this.setComponent();

      // font style set
      const previewWrap = document.querySelector(".preview-wrap");
      const style = document.createElement("style");
      style.innerHTML = this.theme.typefaces.css;
      previewWrap.appendChild(style);

      // component draw
      for (let id of this.componentIds) {
        this.draw(id);
      }
    },
    // component draw
    draw(id) {
      // container set
      const container = this.componentContainer.get(id);
      const options = {};
      for (let option of container.linkedOptions) {
        const values = {...option.value, ...{visible: option.visible}};
        this.$set(options, option.componentOption.key, values);
      }
      this.$set(container, "optionValues", options);

      // div tag add
      const preview = document.querySelector(".preview");
      const div = document.createElement("div");

      div.setAttribute("id", `component${id}`);
      preview.appendChild(div);

      // attach shadow
      const component = document.querySelector(`#component${id}`);
      const device = this.device;
      const theme = this.theme ? this.theme.theme : {};
      component.attachShadow({mode: "open"});

      window.setRender[id] = function (fn) {
        componentController[id] = fn;
        componentController[id](
          component.shadowRoot,
          container.optionValues,
          device,
          theme
        );
      };

      const script = document.createElement("script");
      script.setAttribute(
        "src",
        `${container.renderScript}?type=jsonp&callback=setRender[${id}]`
      );
      script.setAttribute("data-type", `render${id}`);
      document.head.appendChild(script);

      // margin, display set
      this.setMargin(id);
      this.setDisplay(id);
    },
    // component data set
    setComponent() {
      const componentLayout = this.components.componentLayout[this.page];
      this.componentIds = [
        ...componentLayout["header"],
        ...componentLayout["content"],
        ...componentLayout["footer"]
      ];
      this.componentContainer = this.components.componentContainer[this.page];
    },
    // component margin set
    setMargin(id) {
      const component = document.querySelector(`#component${id}`);
      const containerEtc = this.componentContainer.get(id).etc;
      if (containerEtc) {
        component.style.borderTopWidth = `${containerEtc.marginTop}px`;
        component.style.borderLeftWidth = `${containerEtc.marginLeft}px`;
        component.style.borderRightWidth = `${containerEtc.marginRight}px`;
        component.style.borderBottomWidth = `${containerEtc.marginBottom}px`;
      }
    },
    // component display set
    setDisplay(id) {
      const component = document.querySelector(`#component${id}`);
      const container = this.componentContainer.get(id);
      if (
        !container.visible ||
        (!container.displayOnMobile && this.device === "mobile") ||
        (!container.displayOnPc && this.device === "desktop")
      ) {
        component.style.display = "none";
      } else {
        component.style.display = null;
      }
    },
    // typefaces set
    setTypefaces() {
      const style = document.querySelector(".preview-wrap style");
      style.innerHTML = null;
      style.innerHTML = this.theme.typefaces.css;
    },
    // component redraw
    reDraw(id) {
      const component = document.querySelector(`#component${id}`);
      const container = this.componentContainer.get(id);
      const options = {};
      for (let option of container.linkedOptions) {
        const values = {...option.value, ...{visible: option.visible}};
        this.$set(options, option.componentOption.key, values);
      }
      componentController[id](
        component.shadowRoot,
        options,
        this.device,
        this.theme ? this.theme.theme : {}
      );
    },
    // component remove
    remove(id) {
      const component = document.querySelector(`#component${id}`);
      if (component) component.remove();

      const script = document.querySelector(`script[data-type="render${id}"]`);
      if (script) script.remove();
    },
    // component add
    add() {
      const preview = document.querySelector(".preview");
      for (let id of this.componentIds) {
        let component = document.querySelector(`#component${id}`);
        if (!component) {
          this.draw(id);
          setTimeout(() => {
            this.focus(id);
          }, 1000);
        } else {
          preview.append(component);
        }
      }
    },
    // component move
    move() {
      const preview = document.querySelector(".preview");
      for (let id of this.componentIds) {
        const component = document.querySelector(`#component${id}`);
        preview.append(component);
      }
    },
    // component focus
    focus(id) {
      const component = document.querySelector(`#component${id}`);
      const parentWidth = window.parent.document.documentElement.clientWidth;
      const previewWidth = document.querySelector(".preview-wrap").clientWidth;

      let componentTop = component.offsetTop;
      if (parentWidth < 1024 && this.device === "desktop") {
        const scaleRate = previewWidth / 1280;
        componentTop *= scaleRate;
      }
      document.querySelector(".preview-wrap").scrollTo({
        top: componentTop,
        left: 0,
        behavior: "smooth"
      });
    },
    // component scale(pc mode)
    scale() {
      const preview = document.querySelector(".preview");
      const previewWrap = document.querySelector(".preview-wrap");
      const parentWidth = window.parent.document.documentElement.clientWidth;
      const previewWidth = document.querySelector(".preview-wrap").clientWidth;

      // desktop mode에서 1024 이하일때 scale로 화면 전환
      if (parentWidth < 1024 && this.device === "desktop") {
        const scaleRate = previewWidth / 1280;
        preview.style.width = "1280px";
        preview.style.height = 0;
        preview.style.transform = "scale(" + scaleRate + ")";
        preview.style.transformOrigin = "0 0";
        previewWrap.style.overflowX = "hidden";
      } else {
        preview.style = null;
        previewWrap.style = null;
      }
    },
    // component refresh
    refresh() {
      const preview = document.querySelector(".preview");
      let index = 1;
      for (let node of preview.childNodes) {
        const nodeId = node.getAttribute("id")?.substr(9);
        // 저장하기 후 id가 변경된 경우
        if (nodeId !== index) {
          // component id 수정
          node.setAttribute("id", `component${index}`);
        }
        index++;
      }

      for (let id of this.componentIds) {
        const container = this.componentContainer.get(id);
        const component = document.querySelector(`#component${id}`);

        const options = {};
        for (let option of container.linkedOptions) {
          const values = {...option.value, ...{visible: option.visible}};
          this.$set(options, option.componentOption.key, values);
        }
        // controller 수정
        const device = this.device;
        const theme = this.theme ? this.theme.theme : {};
        window.setRender[id] = function (fn) {
          componentController[id] = fn;
          componentController[id](component.shadowRoot, options, device, theme);
        };
        // script 수정
        const scriptId = document.querySelector(
          `script[data-type="render${id}"]`
        );
        scriptId.remove();
        const script = document.createElement("script");
        script.setAttribute(
          "src",
          `${container.renderScript}?type=jsonp&callback=setRender[${id}]`
        );
        script.setAttribute("data-type", `render${id}`);
        document.head.appendChild(script);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.preview-wrap {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;

  .preview {
    &::v-deep > div {
      border-style: solid;
      border-color: rgba(0, 0, 0, 0);
      border-width: 0;
    }
  }
}
</style>
